<template>
  <v-app>
    <section class="mt-16">
      <v-row v-if="result">
        <v-col md="8" class="mx-auto">
          <v-toolbar flat>
            <v-tabs v-model="tab">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(cat, catindex) in result.categories"
                :key="catindex"
              >
                БҮЛЭГ-{{ cat.name }} ({{ cat.questions.length }})
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(cat, catindex) in result.categories"
              :key="catindex"
            >
              <v-row justify="space-between" class="mr-4 mt-4">
                <span class="ml-10">Нийт оноо: </span>
                <span class="ml-2 font-weight-bold red--text">{{
                  cat.catScored
                }}</span>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeSlided = !closeSlided">
                  <span v-if="closeSlided">нээx</span>
                  <span v-else>xааx</span></v-btn
                >
              </v-row>
              <div
                v-if="closeSlided == false"
                v-html="_getSlideLink(cat)"
                class="mt-4"
              ></div>

              <v-simple-table class="asuulga-table mt-4">
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(question, qindex) in cat.questions"
                      :key="qindex"
                    >
                      <td
                        style="width: 1%; background-color: #ececec"
                        class="font-weight-bold"
                      >
                        {{ question.name }}
                      </td>
                      <td
                        class="pa-0"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        :class="answer.answered == true ? 'bordercell' : ''"
                      >
                        {{ answer.name }}
                      </td>
                      <td style="width: 2%" class="font-weight-bold red--text">
                        {{ question.questionScored }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </section>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    closeSlided: false,
    showScores: false,
    firstName: null,
    tab: null,

    result: null,
    categories: null,
    loading: false,
    rules: [],
  }),
  props: {
    sorilId: {
      type: String,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(this.sorilId);
    this.tab = this.tabIndex
    fb.dbeyesh800
      .doc(this.sorilId)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.result = doc.data();
          this.result.ref = doc.ref;
          this.result.id = doc.id;
          // console.log(this.result)
          //await this._findCategories();
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important;'"
        );
      return xx;
    },

    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },
    _getTotalScore2(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered && answer.correct) {
              question.questionScored =
                question.questionScored + question.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    _answerOpenQuestionInput(question) {
      console.log(question.answeredText);
    },
    _answerDefaultQuestion(question, answer) {
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _getQuestionsOfCategory(cat) {
      this.result.ref
        .collection("categories")
        .doc(cat.id)
        .collection("questions")
        .orderBy("name", "asc")

        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.answers = [];
            question.answered = false;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = [];
            //console.log(doc.ref);
            // this._getAnswers(question, doc.ref);

            doc.ref
              .collection("answers")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  const answer = doc.data();
                  answer.id = doc.id;
                  answer.answered = false;
                  question.answers.push(answer);
                });
              });

            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.result.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            console.log(cat.name);
            // this._getQuestionsOfCategory(cat);
            // this.categories.push(cat);
            // if (this.categories.length === 1) {
            //   this.category = this.categories[0];
            // }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
